import { NameSpaces } from '@constants';
import { EnterpriseGraphQLSchema, RegistredWidgetLibrary, WidgetApproved } from '@typings';
import Registry from '@widgets';

export const getRegistryWidget = (widget: EnterpriseGraphQLSchema.DetachedWidget): WidgetApproved | null => {
    const detachedNamespace = 'type' in widget ? widget.type.namespace : widget.namespace;
    const namespace = detachedNamespace as NameSpaces;
    const RegistryArray = Object.keys(Registry);
    const exists = RegistryArray.some((id) => namespace === id);

    if (exists) {
        const knownWidget: RegistredWidgetLibrary | undefined = Registry[namespace];

        if (knownWidget) {
            return {
                ...knownWidget,
                id: 'type' in widget ? widget.type.id : widget.id,
                widgetId: widget.id,
            };
        }
    }

    return null;
};

export const getRegistryWidget2 = () => {};
